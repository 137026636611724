import React from "react"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import SEO from "../../components/SEO"
import { useLanguage } from "../../contexts/LanguageContext"
const Transport = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <Layout>
      <SEO title="Kontakt" />
      <div className="img-wrapper img-wrapper-about">
        <Image
          Tag="div"
          fluid={fluid}
          className=" bcg bcg-about bcg-contact "
        />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter contact-header ">
            We are available for all your inquiries!
          </h2>
        ) : (
          <h2 className="about-header visibility-filter contact-header ">
            Za sve Vaše upite stojimo na raspolaganju!
          </h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">Contact</h2>
        ) : (
          <h2 className="about-header mobile-visible">Kontakt</h2>
        )}
      </div>
      <div className="about-content">
        {selectedLanguage === "EN" ? (
          <div className="contact-content-wrapper">
            <h3 className="contact-us-header contact-us-header-nabava">
              DEPARTMENT OF TRANSPORT, CONSTRUCTION MECHANISMS AND WORKSHOPS
            </h3>
            <ul className="contact-us-list">
              <li className="contact-list-name">Milenko Jurčević</li>
              <li>
                <address>
                  email:{" "}
                  <a href="mailto:  milenko.jurcevic@osijek-koteks.hr">
                    milenko.jurcevic@osijek-koteks.hr
                  </a>
                </address>
              </li>
            </ul>
          </div>
        ) : (
          <div className="contact-content-wrapper">
            <h3 className="contact-us-header contact-us-header-nabava">
              ODJEL TRANSPORTA, GRAĐEVINSKE MEHANIZACIJE I RADIONE
            </h3>
            <ul className="contact-us-list">
              <li className="contact-list-name">Milenko Jurčević</li>
              <li>
                <address>
                  email:{" "}
                  <a href="mailto:  milenko.jurcevic@osijek-koteks.hr">
                    milenko.jurcevic@osijek-koteks.hr
                  </a>
                </address>
              </li>
            </ul>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "imovina-na-prodaju-sl.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Transport
